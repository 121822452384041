import './Card.css'

async function showFact() {
    let fact = await fetchFact();
    document.getElementById('factPanel').textContent= fact
}

async function fetchFact() {
  const catFactsUrl = new URL('https://catfact.ninja/fact');
  const res = await fetch(catFactsUrl);
  const catFact = await res.json();
  console.log(catFact)
  return catFact.fact
}

showFact();

function Card(s) {
    return (
        <div className='card'>
            <label>Cat Fact</label>
            <p id="factPanel"></p>
            <button type="button" onClick={showFact}>Next</button>
        </div>

    );
}

export default Card;